<template>
  <div>
    <g id="house" transform="translate(-0.781 -1.887)">
      <path
        id="Path_7"
        data-name="Path 7"
        d="M3.125,21.094V10.938H4.688V21.094a.781.781,0,0,0,.781.781H19.532a.781.781,0,0,0,.781-.781V10.938h1.563V21.094a2.344,2.344,0,0,1-2.344,2.344H5.469a2.344,2.344,0,0,1-2.344-2.344ZM20.313,3.906V9.375L17.188,6.25V3.906a.781.781,0,0,1,.781-.781h1.563a.781.781,0,0,1,.781.781Z"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M11.4,2.344a1.563,1.563,0,0,1,2.209,0L23.99,12.728a.782.782,0,0,1-1.106,1.106L12.5,3.448,2.116,13.834A.782.782,0,1,1,1.01,12.728Z"
      />
    </g>

    <svg viewBox="-1 -2 25 25">
      <use xlink:href="#house"></use>
    </svg>
  </div>
</template>

<script>
export default {
  name: "HomeSvg",
};
</script>

<style></style>
