<template>
  <div
    class="modal fade"
    id="impersonateModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            Impersonate User
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <DataTable
            :value="listUser"
            :lazy="true"
            :paginator="true"
            :rows="10"
            ref="dt"
            :totalRecords="totalRecords"
            :loading="loading"
            @page="onPage($event)"
            @sort="onSort($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          >
            <Column
              field="id"
              header="id"
              filterMatchMode="startsWith"
              ref="id"
              :sortable="true"
            >
              <template #filter>
                <InputText
                  type="number"
                  v-model="filters['id']"
                  @keydown.enter="onFilter($event)"
                  class="p-column-filter"
                  placeholder="Search id"
                />
              </template>
            </Column>
            <Column
              field="firstName"
              header="firstName"
              filterMatchMode="startsWith"
              ref="firstName"
              :sortable="true"
            >
              <template #filter>
                <InputText
                  type="text"
                  v-model.lazy="filters['firstName']"
                  @keydown.enter="onFilter($event)"
                  class="p-column-filter"
                  placeholder="Search firstName"
                />
              </template>
            </Column>
            <Column
              field="email"
              header="email"
              filterField="email"
              filterMatchMode="contains"
              ref="email"
              :sortable="true"
            >
              <template #filter>
                <InputText
                  type="text"
                  v-model="filters['email']"
                  @keydown.enter="onFilter($event)"
                  class="p-column-filter"
                  placeholder="Search email"
                />
              </template>
            </Column>
            <Column
              field="teleponPerujuks"
              header="nomor"
              filterMatchMode="contains"
              ref="teleponPerujuks"
              :sortable="true"
            >
              <template #body="slotProps">
                +{{ slotProps.data.teleponPerujuks[0].nomor }}
              </template>
              <template #filter>
                <InputText
                  type="text"
                  v-model="filters['teleponPerujuks']"
                  @keydown.enter="onFilter($event)"
                  class="p-column-filter"
                  placeholder="Search telepon"
                />
              </template>
            </Column>
            <Column>
              <template #body="slotProps">
                <button
                  class="btn btn-light"
                  @click.prevent="goImpersonate(slotProps.data)"
                >
                  <i class="fas fa-sign-in-alt mr-1"></i>
                  Impersonate
                </button>
              </template>
              <template #filter>
                <button
                  class="btn btn-primary mr-2"
                  @click.prevent="onLazyEvent()"
                >
                  Search
                </button>
                <button
                  v-if="
                    filters.id ||
                      filters.firstName ||
                      filters.email ||
                      filters.teleponPerujuks
                  "
                  class="btn btn-danger"
                  @click.prevent="clearFilter()"
                >
                  Clear
                </button>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
export default {
  data() {
    return {
      loading: false,
      totalRecords: 0,
      customers: null,
      listUser: null,
      filters: {},
      lazyParams: {},
      columns: [
        { field: "id", header: "id" },
        { field: "firstName", header: "firstName" },
        { field: "email", header: "Email" },
        { field: "teleponPerujuks", header: "nomor" },
      ],
    };
  },
  components: {
    DataTable,
    Column,
    InputText,
  },
  methods: {
    goImpersonate(data) {
      axios
        .post(`/mandayauaa/api/login/impersonate`, {
          userId: data.userId,
        })
        .then(({ data }) => {
          localStorage.setItem("old_token", localStorage.token);
          localStorage.setItem("token", data.access_token);
          this.$router.go("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onLazyEvent() {
      let filters = {};
      for (let i = 0; i < this.columns.length; i++) {
        let obj = {};
        obj["matchMode"] =
          this.$refs[this.columns[i].field].filterMatchMode || "startsWith";
        obj["value"] = this.filters[this.columns[i].field] || null;
        filters[this.columns[i].field] = obj;
      }

      let fill = {
        "firstName.contains": filters ? filters.firstName.value : "",
        "email.contains": filters ? filters.email.value : "",
        "telepon.contains": filters ? filters.teleponPerujuks.value : "",
        "id.equals": filters ? filters.id.value : "",
        page:
          this.lazyParams.filters && this.totalRecords < 11
            ? 0
            : this.lazyParams.page,
        size: 10,
      };

      this.loading = true;
      // console.log(this.lazyParams, filters);

      this.getListUser(fill);
    },
    clearFilter() {
      this.filters = {};
      this.getListUser({});
    },
    onPage(event) {
      this.lazyParams = event;
      this.onLazyEvent();
    },
    onSort(event) {
      this.lazyParams = event;
      this.onLazyEvent();
    },
    onFilter() {
      this.lazyParams.first = 0;
      this.onLazyEvent();
    },
    getListUser(params) {
      axios
        .get(`/api/perujuks`, {
          params,
          // headers: {
          //   'X-TenantId': tenantId
          // },
        })
        .then((data) => {
          this.listUser = data.data;
          this.totalRecords = parseInt(data.headers["x-total-count"]);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    let vx = this;
    // this.getListUser();
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.onLazyEvent();
    $("#impersonateModal").on("hidden.bs.modal", function() {
      vx.clearFilter();
    });
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 90vw;
}
</style>
