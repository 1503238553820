import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Home,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: '/referral',
        name: 'ReferralPage',
        component: () => import('../views/Rujukan.vue')
      },
    ]
  },
  {
    path: '/loginPage',
    component: () => import('../views/LoginPage.vue'),
    children: [
      {
        path: '',
        name: 'LoginPage',
        component: () => import('../components/login/SignIn.vue')
      },
      {
        path: 'register',
        name: 'RegisterPage',
        component: () => import('../components/login/Register.vue')
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('../components/login/ResetPassword.vue')
      },
      {
        path: 'confirm-reset-password',
        name: 'ConfirmResetPassword',
        component: () => import('../components/login/ConfirmResetPassword.vue')
      },
      {
        path: 'verifikasi',
        name: 'Verifikasi',
        component: () => import('../components/login/Verifikasi.vue')
      }
    ]
  },
  {
    path: '/pengaturan',
    name: 'pengaturan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Pengaturan.vue')
  },
  {
    path: '/profileEdit',
    name: 'profileEdit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ProfileEdit.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state.isTutorial) {
    let token = localStorage.getItem('token')
    if (to.name !== 'LoginPage'
      && to.name !== 'RegisterPage'
      && to.name !== 'ResetPassword'
      && to.name !== 'ConfirmResetPassword'
      && to.name !== 'Verifikasi'
    ) {
      if (token && token !== undefined) {
        next()
      } else {
        next('/loginPage')
      }
    } else if (to.name == 'LoginPage' && token) {
      next({ name: from.name })
    } else if (to.name == 'Verifikasi') {
      if (!sessionStorage.verified) {
        next({ name: 'RegisterPage' })
      }
    } else if (to.name == 'ConfirmResetPassword') {
      if (!sessionStorage.resetPas) {
        next({ name: 'ResetPassword' })
      }
    }
  }
  next()
})

export default router
