import Vue from 'vue'
import Vuex from 'vuex'
import axios from "@/axios";
import introJS from "intro.js"
import router from '../router'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isTutorial: false,
    isShowPanel: false,
    referralData: {},
    referralCards: [],
    headerReff: {},
    loading: false,
    listPenjamins: [],
    listRujukans: [],
    isModalAdd: false,
    recordsPage: 0,
    latestParams: {},
    modalFilter: false,
    profileUser: {},
    getFilters: ['PASIEN_BELUM_MASUK', 'PASIEN_DATANG', 'DIRUJUK_KETEMPAT_LAIN', 'DALAM_PERAWATAN', 'PASIEN_PULANG', 'TIDAK_JADI_DATANG'],
    getUrut: 'jejakTerakhirTimestamp',
    getArah: "desc",
    realData: [],
    listTenants: [],
    allDummyData: [],
    dummyData: {},
    routeTutorial: '',
    roleAdmin: false,
    userName: '',
    isProgressTutorial: false,
    defaultOptionsIntro: {
      showBullets: false,
      hidePrev: true,
      keyboardNavigation: false
    },
    kategoriUser: {
      id: '',
      name: ''
    }
  },
  mutations: {
    SET_KATEGORI_USER_ID(state, res) {
      state.kategoriUser.id = res
    },
    SET_KATEGORI_USER_NAME(state, res) {
      state.kategoriUser.name = res
    },
    SET_GET_URUT(state, res) {
      state.getUrut = res
    },
    SET_GET_ARAH(state, res) {
      state.getArah = res
    },
    SET_GET_FILTER(state, res) {
      state.getFilters = res
    },
    SET_REAL_DATA(state, res) {
      state.realData = res
    },
    SET_PROGRESS_TUTORIAL(state, res) {
      state.isProgressTutorial = res
    },
    SET_ROUTE_TUTORIAL(state, res) {
      state.routeTutorial = res
    },
    SET_PAGE_TUTORIAL(state, res) {
      state.isTutorial = res
    },
    SET_REFERRAL_DATA(state, payload) {
      state.referralData = payload
      state.headerReff = payload.headers
      state.referralCards = payload.data.data
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_PROFILE_USER(state, payload) {
      state.profileUser = payload
    },
    SET_MODAL_FILTER(state, payload) {
      state.modalFilter = payload
    },
    SET_LATEST_PARAMS(state, payload) {
      state.latestParams = payload
    },
    SET_IS_MODAL_ADD(state, payload) {
      state.isModalAdd = payload
    },
    SET_LIST_PENJAMINS(state, payload) {
      state.listPenjamins = payload
    },
    SET_LIST_RUJUKANS(state, payload) {
      state.listRujukans = payload
    },
    SET_RECORDS_PAGE(state, payload) {
      state.recordsPage = payload
    },
    SET_LIST_TENANTS(state, payload) {
      var base64Url = payload.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      let decoded = JSON.parse(jsonPayload)
      var admin = (decoded.authorities.indexOf("ROLE_ADMIN") > -1);
      admin ? state.roleAdmin = true : state.roleAdmin = false 
      state.userName = decoded.user_name
      state.listTenants = decoded.tenants
    },
    SET_SHOW_PANEL(state, res) {
      state.isShowPanel = res
    },
    SET_DUMMY_DATA(state, res) {
      state.dummyData = res
    },
    SET_ALL_DUMMY_DATA(state, res) {
      state.allDummyData = res
    },
  },
  actions: {
    GET_RUJUKANS: async function (context, tenantId) {
      axios.get(`/api/tujuan-rujukans?size=1000`,
        {
          headers: {
            'X-TenantId': tenantId
          },
        })
        .then(({ data }) => {
          context.commit('SET_LIST_RUJUKANS', data)
          // context.commit('SET_LOADING', false)

          return true;
        })
        .catch(err => {
          console.log(err);
        })
    },
    GET_TENANTS: async function (context, tenantId) {
      let token = localStorage.getItem('token')
      axios.get(`/mandayauaa/api/tenant-users`,
        {
        })
        .then(({ data }) => {
          const map1 = data.filter(x => x.id !== 1);
          context.commit('SET_LIST_TENANTS', map1)
          // context.commit('SET_LOADING', false)

          return true;
        })
        .catch(err => {
          console.log(err);
        })
    },
    GET_PENJAMINS: async function (context, tenantId) {
      axios.get(`/api/jenis-penjamins`,
        {
          headers: {
            'X-TenantId': tenantId
          },
        })
        .then(({ data }) => {
          context.commit('SET_LIST_PENJAMINS', data)
          // context.commit('SET_LOADING', false)

          return true;
        })
        .catch(err => {
          console.log(err);
        })
    },
    GET_REFERRALS: async function (context, { params }) {
      context.commit('SET_LOADING', true)
      axios.get(`/api/transaksi-referrals/all-tenant`,
        {
          params,
        })
        .then(data => {
          Object.keys(data.headers).map(function (key, index) {
            if (key == 'x-total-count') {
              context.commit('SET_RECORDS_PAGE', Number(data.headers[key]))
            }
          });
          context.commit('SET_REFERRAL_DATA', { data })
          context.commit('SET_REAL_DATA', { data })
          context.commit('SET_LATEST_PARAMS', params)
          context.commit('SET_LOADING', false)

          return true;
          // this.referralCards = data.data
        })
        .catch(err => {
          context.commit('SET_LOADING', false)
        })
    },
    hideSidePanel({ commit }) {
      const sidePanel = document.getElementById('sidepanel-intro')
      sidePanel.style.right = '-300px'
      setTimeout(() => {
        commit('SET_SHOW_PANEL', false)
      }, 900)
    },
    parseElement({ state }, menu) {
      const changeToDoc = (element) => {
        if (element.type == 'className') {
          return document.getElementsByClassName(element.value)[element.index]
        } else if (element.type == 'id') {
          return document.getElementById(element.value)
        } else if (element.type == 'selector') {
          return document.querySelectorAll(element.value)[element.index]
        }
      }
      if (menu.steps) {
        const data = menu.steps.map(item => {
          return {
            ...item,
            ...(item.elements ? { element: changeToDoc(item.elements) } : null)
          }
        })
        menu.steps = data
      }

      return { ...menu, ...state.defaultOptionsIntro }
    },
    parseFunction({ }, funct) {
      let strFunction = ''
      if (funct && funct.body && funct.body.length > 0) {
        const body = funct.body
        for (let key in body) {
          if (key == 0) {
            strFunction = `if(this._currentStep == ${body[key].step}){${body[key].action}}`
          } else {
            strFunction += `else if(this._currentStep == ${body[key].step}){${body[key].action}}`
          }
        }
      } else {
        return () => { }
      }
      const functionAction = funct.arguments ? new Function(funct.arguments, strFunction) : () => { }
      return functionAction
    },
    showSearchSidePanel({ commit, dispatch }, { data }) {
      dispatch('hideSidePanel')
      setTimeout(() => {
        commit('SET_PROGRESS_TUTORIAL', false)
        dispatch('goToIntro', { nextStep: data, index: 0, maxIndex: data.length })
      }, 100)
    },
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    },
    async goToIntro({ state, commit, dispatch }, { nextStep, index, maxIndex }) {
      commit('SET_PAGE_TUTORIAL', true)
      commit('SET_DUMMY_DATA', {})
      // commit('SET_PROGRESS_TUTORIAL', true)
      if (nextStep[index].dummyDataId) {
        const dummy = state.allDummyData
        const dummyData = dummy.filter(item => item.id == nextStep[index].dummyDataId)
        commit('SET_DUMMY_DATA', JSON.parse(dummyData[0].example))
      }
      const introMenuName = nextStep[index].options
      await dispatch('wait', 200)
      const options = await dispatch('parseElement', introMenuName)
      const functionAction = await dispatch('parseFunction', nextStep[index].events)
      introJS()
        .refresh()
        .onbeforechange(functionAction)
        .setOptions(options)
        .start()
        .oncomplete(() => {
          commit('SET_PAGE_TUTORIAL', false)
          if (maxIndex > index + 1) {
            commit('SET_PROGRESS_TUTORIAL', true)
            dispatch('goToIntro', { nextStep, index: index + 1, maxIndex })
          }
        })
        .onexit(() => {
          if (!state.isProgressTutorial) {
            const data = document.querySelectorAll('.dropdown-menu.show')
            const modal = document.querySelectorAll('.modal.show')
            const modalTracking = document.querySelectorAll('#Modal921651')
            const pBlock = document.querySelectorAll('.p-block')
            if (pBlock.length > 0) {
              pBlock[0].classList.remove('p-block')
            }
            if (data.length > 0) {
              data[0].classList.remove('show')
            }
            if (modal.length > 0) {
              document.querySelectorAll('.modal-header>button.close')[0].click()
            }
            if (modalTracking.length > 0) {
              document.querySelectorAll('#Modal921651 button.close')[0].click()
            }
            const url = window.location.hash.slice(1)
            commit('SET_DUMMY_DATA', {})
            if (url !== state.routeTutorial) {
              router.push(state.routeTutorial).catch(() => { })
            }
          }
          commit('SET_PROGRESS_TUTORIAL', false)
        })
    }
  },
  modules: {
  }
})
