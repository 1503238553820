import axios from "axios";
import router from '../router'

var axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  // headers: {
  //   // Authorization: `Bearer ${token}`,
  //   // 'X-TenantId': tenantId
  // }
})


axiosInstance.interceptors.request.use((config) => {
  
  let token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config
}, (error) => {
  console.log("Interceptor Request Error" + error)
})

axiosInstance.interceptors.response.use((response) => {
  return response
}, (error) => {
  if(error.response.status == 401) {
    localStorage.removeItem("token");
    localStorage.setItem('errorToken', 'Token Expired, Silahkan Login kembali')
    router.go({name: "LoginPage"})
  }
  // console.log(error.response)
  // if(error.response.)
  // 
  // console.log(error.response.data, 'error data');
  
  // console.log(error);
  return Promise.reject(error);
})

export default axiosInstance
