<template>
  <div id="app">
     <!-- <a href="#" class="float" id="tutorial" data-toggle="tooltip" data-placement="left" title="klik 2 kali untuk tutorial">
      <i class="fas fa-question my-float"></i>
      <span class="circle-bg-tutorial" id="tutorial1" @click.prevent="checkSidepanel"></span>
    </a>
    <side-panel v-if="sidepanel">
      <label class="btn-close" @click="hidePanel">×</label>
    </side-panel> -->
    <router-view></router-view>
  </div>
</template>
<script>
// import SidePanel from './components/Tutorial/Sidepanel.vue'
export default {
  data() {
    return {
      initialX: '',
      initialY: '',
      active: false,
      dragItem: '',
      dragItem2: '',
      container: '',
      currentX: '',
      currentY: '',
      xOffset: 0,
      yOffset: 0,
      doubleClicked: false
    }
  },
//  computed: {
//     sidepanel: {
//       get() {
//         return this.$store.state.isShowPanel
//       },
//       set(val) {
//         return this.$store.commit('SET_SHOW_PANEL', val)
//       }
//      }
//  },
//   components: {
//     SidePanel
//   },
  methods:{
    checkSidepanel() {
        if (this.doubleClicked) {
          $('#tutorial').tooltip('hide')
            this.sidepanel = true
            setTimeout(()=> {
              document.getElementById('sidepanel-intro').style.right = '0px'
            }, 100)
        }
        this.doubleClicked = true;
        $('#tutorial').tooltip('show')
        setTimeout(() => {
            this.doubleClicked = false;
        }, 300);
      },
      hidePanel() {
        this.$store.dispatch('hideSidePanel')
      },
      dragStart(e) {
      if (e.type === "touchstart") {
        this.initialX = e.touches[0].clientX - this.xOffset;
        this.initialY = e.touches[0].clientY - this.yOffset;
      } else {
        this.initialX = e.clientX - this.xOffset;
        this.initialY = e.clientY - this.yOffset;
      }

      if ( e.target == this.dragItem2) {
        this.active = true;
      }
    },
    dragEnd(e) {
      this.initialX = this.currentX;
      this.initialY = this.currentY;

      this.active = false;
    },
    drag(e) {
      if (this.active) {
      
        e.preventDefault();
      
        if (e.type === "touchmove") {
          this.currentX = e.touches[0].clientX - this.initialX;
          this.currentY = e.touches[0].clientY - this.initialY;
        } else {
          this.currentX = e.clientX - this.initialX;
          this.currentY = e.clientY - this.initialY;
        }

        this.xOffset = this.currentX;
        this.yOffset = this.currentY;

        this.setTranslate(this.currentX, this.currentY, this.dragItem);
      }
    },
    setTranslate(xPos, yPos, el) {
      el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
      // el2.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";

    }
  },
  mounted(){
    
    $('#tutorial').tooltip()
    let token = localStorage.getItem('token')
    if (token) {
      this.$router.push('/referral')
      this.$store.commit("SET_LIST_TENANTS", token);
    }else{
      this.$router.push('/loginPage')
    }
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

  .carousel-indicators li {
    width: 12px;
    height: 12px;
    border-radius: 100%;
  }

</style>
