import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueFab from 'vue-float-action-button'
import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css'
import PrimeVue from 'primevue/config';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'intro.js/minified/introjs.min.css'

Vue.use(Toast);
Vue.use(PrimeVue);
// Vue.config.productionTip = false

Vue.use(VueFab,  {
  // opitons 可选iconfont图标或MaterialIcons
  iconType: 'MaterialDesign',
  // iconType: 'iconfont'
} )
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
